import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Field = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Label = styled.label`
  min-width: 60px;
`;

export const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  flex: 1;
  margin: 0;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

export const Options = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const OptionButton = styled.button<{ active?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: ${(p) => (p.active ? '#5b4f47' : '#fff')};
  color: ${(p) => (p.active ? '#fff' : '#000')};
  flex: 1;
  font-size: 14px;
`;

export const SubmitButton = styled.button`
  padding: 14px 16px;
  border-radius: 20px;
  flex: 1;
  background-color: #5b4f47;
  color: #fff;
  border: none;

  &:disabled {
    opacity: 0.6;
  }
`;
