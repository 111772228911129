import { styled } from 'styled-components';
import { getElementDescription, getElementsStat, getGodsStat, getHorse } from '../../util';
import { getGodsDescriptionArr, getHouseDescriptionArr } from 'app/util/description';
import { useEffect, useState } from 'react';
import { getChat } from 'app/services/api';

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  margin-top: 16px;
  padding: 16px;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const Button = styled.button`
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding: 8px;
  width: fit-content;
`;

const ToggleButton = styled.button`
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
`;

const Desc = styled.p`
  white-space: pre-line;
`;

const TextArea = styled.textarea`
  height: 1000px;
  width: 100%;
  margin: 0;
`;

export default function Description(props: any) {
  const { summary, descriptionArr } = props;
  const [isToggle, setIsToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState<string>();
  const godsStat = getGodsStat(summary) || {};
  const elementsStat = getElementsStat(summary) || {};
  const godsDescriptionArr = getGodsDescriptionArr({ summary, godsStat, descriptionArr });
  const horseStats = getHorse(summary) || {};
  const horseDescriptionArr = getHouseDescriptionArr({ summary, horseStats });
  const [promptText, setPromptText] = useState<string>('');

  const poolTotal = Object.values(godsStat)
    .flat()
    .reduce((acc, i) => acc + i.value, 0);

  const godArr = Object.entries(godsStat)
    .map(([key, stat]) => {
      const total = stat.reduce((acc, i) => acc + i.value, 0) as number;
      return {
        name: key,
        total,
        percentage: poolTotal ? total / poolTotal : 0,
        stat,
      };
    })
    .sort((a, b) => b.total - a.total);

  const filterProsAndCons = godsDescriptionArr.filter((i) => {
    if (i.title === '優點' || i.title === '缺點' || i.title === 'general') {
      return i.name === godArr[0].name;
    }
    return true;
  });

  const descriptionTexts = [
    // @ts-ignore
    ...new Set(
      filterProsAndCons.filter((i) => [godArr[0].name, godArr[1].name].includes(i.name)).map((i) => i.description)
    ),
  ];

  const onGenerateAnswer = async (prompt: string) => {
    setLoading(true);
    const result = await getChat(prompt);
    setAnswer(result);
    setLoading(false);
  };

  useEffect(() => {
    const basicInfo = `${summary.gender}性，${summary.personType}格局`;

    const template = `以八字算命師角度填寫表格，需要根據<>的規則填寫
  - 留意內容之間避免重覆用字，避免有衝突，符合命主性別年齡
  - 主語必須用命主稱呼
  [表格格式]
  正面性格: <50字 分析命主的正面性格及優點>
  負面性格: <50字 分析命主的正面性格及缺點>
  簡述: <100字，概括全部內容，命主形象，命主的喜惡，命主對朋友/異性/家人的態度，命主的感情/事業/健康運如何。如沒有相關內容則跳過>
  [表格格式]
  以下是命主的分析:`;

    const question = [
      template,
      basicInfo,
      Object.entries(elementsStat)
        .map(([key, i]) => getElementDescription(key, i.total))
        .join(''),
      descriptionTexts.join(''),
      horseDescriptionArr
        .map((i) =>
          [i.description, i.conflict.map((i) => i.content).join(''), i.combination.map((i) => i.content).join('')].join(
            ''
          )
        )
        .join(''),
    ];
    setPromptText(question.join('\n'));
  }, []);

  return (
    <>
      <Header>
        <span>Prompt字數: {promptText.length}字</span>
        <ToggleButton onClick={() => setIsToggle(!isToggle)}>{!isToggle ? '顯示Prompt' : '收起Prompt'}</ToggleButton>
      </Header>
      {isToggle && (
        <>
          <DescriptionSection>
            <TextArea value={promptText} onChange={(e) => setPromptText(e.target.value)} />
          </DescriptionSection>
        </>
      )}
      {!loading && (
        <Button disabled={promptText.length > 1700} onClick={() => onGenerateAnswer(promptText)}>
          生成簡批 {promptText.length > 1700 && `字數太多`}
        </Button>
      )}
      {loading && <DescriptionSection>生成中...</DescriptionSection>}
      {answer && (
        <DescriptionSection>
          <Desc>{answer}</Desc>
        </DescriptionSection>
      )}
    </>
  );
}
