import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { CreateUserPage } from './pages/CreateUserPage';
import { ListingPage } from './pages/ListingPage';
import { ReportPage } from './pages/ReportPage';
import { RecordPage } from './pages/RecordPage';

export function App() {
  const subdomain = window.location.host.split('.')[0];
  const isPortal = subdomain === 'portal' || subdomain.includes('localhost');
  const isUser = subdomain === 'user' || subdomain.includes('localhost');

  return (
    <BrowserRouter>
      <Routes>
        {isPortal && <Route path="/" element={<ListingPage />} />}
        {isPortal && <Route path="/create" element={<CreateUserPage />} />}
        {isPortal && <Route path="/dashboard" element={<DashboardPage />} />}
        {isPortal && <Route path="/report" element={<ReportPage />} />}
        {isPortal && <Route path="*" element={<NotFoundPage />} />}
        {isUser && <Route path="/record/:id" element={<RecordPage />} />}
      </Routes>
    </BrowserRouter>
  );
}
