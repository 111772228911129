import { styled } from 'styled-components';
import LogoImage from 'asset/logo.png';
import { faChevronLeft, faClockRotateLeft, faPlus, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import HistorySidebar from '../HistorySidebar';

const Container = styled.div`
  position: sticky;
  top: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  gap: 16px;
  background-color: #f8f7f4;
  z-index: 1000;
  border-bottom: 1px solid #e0e0e0;
`;

const Logo = styled.img`
  height: 48px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const MenuItem = styled.button`
  border: none !important;
  font-size: 16px;
  padding: 8px;
  outline: none;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #000;
  background-color: #ededed;
  width: fit-content;
`;

const RoundedButton = styled.button`
  border: none !important;
  height: 40px;
  width: 40px;
  font-size: 16px;
  outline: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #ededed;
  text-align: center;
  color: #5b4f47;
  padding: 0;
  justify-content: center;
`;

const Trailing = styled.div`
  display: flex;
  gap: 8px;
`;

export default function Header(props: any) {
  const { back, share, create } = props;
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const toggleHistory = () => {
    setShowHistory((i) => !i);
  };

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'profile',
          text: `${window.location.href}`,
          url: window.location.href,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <Container>
      <div>
        {back && (
          <MenuItem onClick={() => (window.location.href = '/')}>
            <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faChevronLeft} />
            返回
          </MenuItem>
        )}
      </div>
      <Logo src={LogoImage} />
      <Trailing>
        {create && (
          <MenuItem onClick={() => (window.location.href = '/create')}>
            創建
            <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faPlus} />
          </MenuItem>
        )}
        {share && (
          <MenuItem onClick={onShare}>
             分享
            <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faShare} />
          </MenuItem>
        )}

        <RoundedButton onClick={toggleHistory}>
          <FontAwesomeIcon style={{ fontSize: '16px' }} icon={faClockRotateLeft} />
        </RoundedButton>
      </Trailing>
      {showHistory && <HistorySidebar onClose={toggleHistory} />}
    </Container>
  );
}
