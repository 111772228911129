import { styled } from 'styled-components';
import { getElementsStat, getElementDescription } from '../../util';
import { toPercentage } from '../../util/common';

const Table = styled.table`
  font-size: 14px;
  padding: 0;
  background: #fff;
  border-collapse: collapse;
  thead th {
    text-align: left;
  }

  th,
  td {
    padding: 16px 8px;
  }

  tr:nth-child(2n-1) td {
    background-color: #f3f6f6;
  }
`;

const ChangeValue = styled.p<{ positive?: boolean }>`
  color: ${(props: any) => (props.positive ? 'green' : 'red')};
`;

export default function ElementStat(props: any) {
  const { summary, selectedYear } = props;
  const basicStat = getElementsStat(summary) || {};
  const yearStat = getElementsStat(summary, '大運', selectedYear) || {};

  const toData = (stat: Record<string, any>) => {
    const poolTotal = Object.values(stat).reduce((acc, i) => acc + i.total, 0);
    const eleArr = Object.entries(stat).map(([key, i]) => ({
      ele: key,
      value: i.total,
      percentage: poolTotal ? i.total / poolTotal : 0,
      description: getElementDescription(key, i.total),
    }));

    return { eleArr, poolTotal };
  };

  const { eleArr } = toData(basicStat);
  const { eleArr: yearArr } = toData(yearStat);

  return (
    <Table>
      <thead>
        <tr>
          <th>五行</th>
          <th>比例</th>
          <th>大運({selectedYear})</th>
          <th>描述</th>
        </tr>
      </thead>
      <tbody>
        {eleArr.map(({ ele, value, percentage, description }, index) => (
          <tr key={ele}>
            <td>{ele}</td>
            <td>
              <p>
                {value}({toPercentage(percentage)})
              </p>
            </td>
            <td>
              {percentage === yearArr[index].percentage ? (
                <p>-</p>
              ) : (
                <div>
                  <ChangeValue positive={yearArr[index].percentage - percentage >= 0}>
                    {yearArr[index].value}({toPercentage(yearArr[index].percentage)})
                  </ChangeValue>
                </div>
              )}
            </td>
            <td>{description}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
