import { Element } from '../type';
export const groupShortTerm: Record<string, string> = {
  天干: '干',
  地支: '支',
};

export const godsTerm: Record<string, string> = {
  官: '正官',
  殺: '七殺',
  印: '正印',
  卩: '偏印',
  財: '正財',
  才: '偏財',
  比: '比肩',
  劫: '劫財',
  食: '食神',
  傷: '傷官',
};

export const godsRatio: any = {
  1: [1],
  2: [0.7, 0.3],
  3: [0.6, 0.3, 0.1],
};

export const elementMap: Record<string, Element[]> = {
  // 天
  甲: [{ type: '木', value: 60 }],
  乙: [{ type: '木', value: 60 }],
  丙: [{ type: '火', value: 60 }],
  丁: [{ type: '火', value: 60 }],
  戊: [{ type: '土', value: 60 }],
  己: [{ type: '土', value: 60 }],
  庚: [{ type: '金', value: 60 }],
  辛: [{ type: '金', value: 60 }],
  壬: [{ type: '水', value: 60 }],
  癸: [{ type: '水', value: 60 }],
  //地
  子: [{ type: '水', value: 100 }],
  丑: [
    { type: '土', value: 60 },
    { type: '水', value: 30 },
    { type: '金', value: 10 },
  ],
  寅: [
    { type: '木', value: 60 },
    { type: '火', value: 30 },
    { type: '土', value: 10 },
  ],
  卯: [{ type: '木', value: 100 }],
  辰: [
    { type: '土', value: 60 },
    { type: '木', value: 30 },
    { type: '水', value: 10 },
  ],
  巳: [
    { type: '火', value: 60 },
    { type: '土', value: 30 },
    { type: '金', value: 10 },
  ],
  午: [
    { type: '火', value: 70 },
    { type: '土', value: 30 },
  ],
  未: [
    { type: '土', value: 60 },
    { type: '火', value: 30 },
    { type: '木', value: 10 },
  ],
  申: [
    { type: '金', value: 60 },
    { type: '水', value: 30 },
    { type: '土', value: 10 },
  ],
  酉: [{ type: '金', value: 100 }],
  戌: [
    { type: '土', value: 60 },
    { type: '金', value: 30 },
    { type: '火', value: 10 },
  ],
  亥: [
    { type: '水', value: 70 },
    { type: '木', value: 30 },
  ],
};
