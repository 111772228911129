import { styled } from 'styled-components';
import { getHorse } from '../../util';
import { getHouseDescriptionArr } from 'app/util/description';

const Table = styled.table`
  font-size: 14px;
  padding: 0;
  background: #fff;
  border-collapse: collapse;
  thead th {
    text-align: left;
  }

  th,
  td {
    padding: 16px 8px;
    white-space: pre-line;
  }

  tr:nth-child(2n-1) td {
    background-color: #f3f6f6;
  }
`;

const Content = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  font-weight: bold;
`;

export default function HorseStat(props: any) {
  const { summary } = props;
  const horseStats = getHorse(summary) || {};
  const arr = getHouseDescriptionArr({ summary, horseStats });

  return (
    <Table>
      <thead>
        <tr>
          <th>字</th>
          <th>數</th>
          <th>描述</th>
        </tr>
      </thead>
      <tbody>
        {arr.map(({ name, value, description, conflict, combination }) => (
          <tr key={name}>
            <td>{name}</td>
            <td>{value}</td>
            <td>
              <Content>{description}</Content>
              {conflict.length > 0 &&
                conflict.map((i, index) => (
                  <Content key={index}>
                    <Title>{i.type}</Title>
                    <p>{i.content}</p>
                  </Content>
                ))}
              {combination.length > 0 &&
                combination.map((i, index) => (
                  <Content key={index}>
                    <Title>{i.type}</Title>
                    <p>{i.content}</p>
                  </Content>
                ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
