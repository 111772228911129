import { styled } from 'styled-components';
import { getGodsStat } from '../../util';
import { groupShortTerm } from '../../config/constants';
import { toPercentage } from '../../util/common';
import { getGodsDescriptionArr } from 'app/util/description';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Table = styled.table`
  font-size: 14px;
  padding: 0;
  background: #fff;
  border-collapse: collapse;
  thead th {
    text-align: left;
  }

  th,
  td {
    padding: 8px;
    white-space: pre-line;
  }

  tr:nth-child(2n-1) td {
    background-color: #f3f6f6;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 2px solid rgb(0, 0, 0);
  padding: 0 24px 2px 0;
  width: fit-content;
`;

export default function GodsStat(props: any) {
  const { summary, descriptionArr } = props;
  const stat = getGodsStat(summary) || {};

  const poolTotal = Object.values(stat)
    .flat()
    .reduce((acc, i) => acc + i.value, 0);
  const godArr = Object.entries(stat)
    .map(([key, stat]) => {
      const total = stat.reduce((acc, i) => acc + i.value, 0) as number;
      return {
        name: key,
        total,
        percentage: poolTotal ? total / poolTotal : 0,
        stat,
      };
    })
    .sort((a, b) => b.total - a.total);

  const godsDescriptionArr = getGodsDescriptionArr({ summary, godsStat: stat, descriptionArr });

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>十神</th>
            <th>詳細</th>
            <th>比例</th>
          </tr>
        </thead>
        <tbody>
          {godArr.map(({ name, percentage, total, stat }) => (
            <tr key={name}>
              <td>
                {name}({total.toFixed(2)})
              </td>
              <td>
                {stat.map((i, index) => (
                  <p key={index}>
                    {i.name
                      ? `${i.position}${groupShortTerm[i.group]} - ${i.name}`
                      : `${i.position}${groupShortTerm[i.group]}`}
                    ({i.value})
                  </p>
                ))}
              </td>
              <td>{toPercentage(percentage)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p style={{ fontSize: '20px', fontWeight: 600, marginBottom: '8px', marginTop: '16px' }}>詳細解說</p>
      {godArr.map(({ name, percentage }) => {
        const descriptionArr = godsDescriptionArr.filter((i) => i.name === name);
        return (
          <div key={name} style={{ marginBottom: '16px' }}>
            <SubTitle>
              {name} ({toPercentage(percentage)})
            </SubTitle>
            {descriptionArr.map((i, index) => (
              <div key={index} style={{ marginBottom: '8px' }}>
                {i.title !== descriptionArr[index - 1]?.title && (
                  <p style={{ fontWeight: 600, marginBottom: '8px', fontSize: '14px' }}>{i.title || '其他'}</p>
                )}
                <p style={{ fontSize: '14px' }}>{i.description}</p>
              </div>
            ))}
          </div>
        );
      })}
    </Container>
  );
}
