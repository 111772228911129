import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getData } from '../../services/api';
import { useSearchParams } from 'react-router-dom';
import DNA from '../../components/DNA';
import User from '../../components/User';
import ElementStat from '../../components/ElementStat';
import Card from '../../components/Card';
import HorseStat from '../../components/HorseStat';
import GodsStat from '../../components/GodsStat';
import Header from 'app/components/Header';
import LogoImage from 'asset/logo.png';
import { getSummary } from 'app/util';
import YearsElementStat from 'app/components/YearsElementStat';
import Description from 'app/components/Description';
import Select from 'react-select';
import InvoiceList from 'app/components/InvoiceList';
import Skeleton from 'react-loading-skeleton';

const Page = styled.div``;

const Logo = styled.img`
  height: auto;
  width: 50vw;
  max-width: 150px;
  border-radius: 32px;
`;

const Loading = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
  background-color: #f6f4ee;
  @media (max-width: 768px) {
    padding: 8px 4px;
  }
`;

export function DashboardPage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [selectedYear, setSelectYear] = useState<number>();
  const id = searchParams.get('id');
  const summary = useMemo(() => (data ? getSummary(data.dna, data.user) : null), [data]);
  const yearOptions = useMemo(
    () => (summary ? summary.years.big.map((i: any) => ({ label: `${i.year}`, value: i.year })) : []),
    [summary]
  );

  const onChangeYear = (data: any) => {
    setSelectYear(data.value);
  };

  const fetchData = async () => {
    setLoading(true);
    const id = searchParams.get('id') as string;
    const res = await getData(id);
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (yearOptions.length > 0) {
      setSelectYear(yearOptions[0].value);
    }
  }, [yearOptions, setSelectYear]);

  if (!id)
    return (
      <Loading>
        <Logo src={LogoImage} />
      </Loading>
    );

  if (loading) {
    return (
      <Page>
        <Header back share />
        <Wrapper>
          <Card sectionId="user" title="顧客資料">
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Card>
          <Card sectionId="user" title="支付紀錄">
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Card>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      <Header back share />
      <Wrapper>
        <Card sectionId="user" title="顧客資料">
          <User data={data} refetch={fetchData} setLoading={setLoading} />
        </Card>
        <Card sectionId="invoice" title="支付紀錄">
          <InvoiceList data={data} refetch={fetchData} setLoading={setLoading} />
        </Card>
        <Card sectionId="bigYear" title="大運選擇">
          <Select
            options={yearOptions}
            defaultValue={yearOptions[0]}
            onChange={onChangeYear}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#F8F0E5',
                primary: '#5b4f47',
              },
            })}
          />
        </Card>
        <Card sectionId="dna" title="DNA">
          <DNA data={data.dna} />
        </Card>
        <Card sectionId="elements" title="五行性格">
          <ElementStat selectedYear={selectedYear} summary={summary} />
        </Card>
        <Card sectionId="years-elements" title={`流年五行分析 - ${selectedYear}`}>
          {selectedYear && <YearsElementStat selectedYear={selectedYear} summary={summary} />}
        </Card>
        <Card sectionId="horse" title="馬花庫分析">
          <HorseStat summary={summary} />
        </Card>
        <Card sectionId="ten" title="十神分析">
          <GodsStat summary={summary} descriptionArr={data.description} />
        </Card>
        <Card sectionId="desc" title="AI">
          <Description summary={summary} descriptionArr={data.description} />
        </Card>
      </Wrapper>
    </Page>
  );
}
