import Card from 'app/components/Card';
import { getAllInvoices, getLatestUsers } from 'app/services/api';
import { customFormatDistance, formatDate } from 'app/util/common';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckToSlot, faRotate, faSearch } from '@fortawesome/free-solid-svg-icons';
import Header from 'app/components/Header';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { Invoice, User } from 'app/type';
import Skeleton from 'react-loading-skeleton';

const Page = styled.div``;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  padding: 16px;
  @media (max-width: 768px) {
    padding: 8px 4px;
  }
  background-color: #f6f4ee;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 8px 16px;
  gap: 8px;
  border: 1px solid #e0e0e0;

  input {
    border: none;
    background: none;
    flex: 1;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserGroup = styled.button`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  min-height: 48px;
  border-radius: 4px;
  padding: 16px;
  border: none;
  background-color: #fff;
  color: #5b4f47;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  font-size: 12px;
`;

const Refresh = styled(FontAwesomeIcon)`
  padding: 4px;
  cursor: pointer;
`;

const Tab = styled.div`
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
`;

const TabItem = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 8px 4px;
  border: none;
  background: none;
  color: #000;
  font-size: 14px;
  ${(props) => (props.active ? 'border-bottom: 3px solid #5b4f47;' : 'border-bottom: 3px solid transparent;')}
`;

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

export function ListingPage() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedStatus, setSelectedStatus] = useState('全部');
  const columnHelper = createColumnHelper<User>();
  const statusOptions = ['全部', '待簡批', '待答覆問題', '已回覆'];
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const onChangeTab = (status: string) => {
    setSelectedStatus(status);
    if (status === '全部') {
      setColumnFilters([]);
    } else {
      setColumnFilters([{ id: 'Status', value: status }]);
    }
  };

  const columns = [
    columnHelper.accessor((row) => formatDate(row['八字'], true), {
      id: 'formattedDate',
    }),
    columnHelper.accessor('背景資料', {}),
    columnHelper.accessor('Status', {}),
  ];

  const table = useReactTable({
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    data: users,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  async function fetchData() {
    setLoading(true);
    const res = await getLatestUsers(-1);
    const invoicesResp = await getAllInvoices();
    res.sort((a, b) => {
      return new Date(b['Create date']).getTime() - new Date(a['Create date']).getTime();
    });
    setUsers(res);
    setInvoices(invoicesResp);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    setSelectedStatus('待簡批');
    setColumnFilters([{ id: 'Status', value: '待簡批' }]);
  }, []);

  return (
    <Page>
      <Header create />
      <Wrapper>
        <Card title="列表" trailing={<Refresh icon={faRotate} onClick={fetchData} />}>
          <Search>
            <FontAwesomeIcon icon={faSearch} style={{ fontSize: '14px' }} />
            <input
              placeholder="搜尋關鍵字"
              value={globalFilter ?? ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </Search>
          <Tab>
            {statusOptions.map((i) => (
              <TabItem active={selectedStatus === i} onClick={() => onChangeTab(i)} key={i}>
                <p>{i}</p>
                {users.filter((user) => user['Status'] === i).length > 0
                  ? `(${users.filter((user) => user['Status'] === i).length})`
                  : ''}
              </TabItem>
            ))}
          </Tab>
          {!loading && (
            <UserList>
              {table.getRowModel().rows.map(({ original: user }) => (
                <UserGroup
                  key={user['UserId']}
                  onClick={() => {
                    window.location.href = `/dashboard?id=${user['UserId']}`;
                  }}
                >
                  <UserInfo>
                    <p style={{ fontWeight: 600, fontSize: '14px' }}>
                      <span style={{ color: user['性別'] === '男' ? '#6d96be' : '#fd8587' }}>{user['性別']}</span>{' '}
                      {formatDate(user['八字'])}
                    </p>
                    <p>{user['背景資料']}</p>
                  </UserInfo>
                  <UserInfo style={{ alignItems: 'flex-end', opacity: 0.8 }}>
                    {selectedStatus === '全部' && <p>{user['Status']}</p>}
                    <p>
                      {invoices
                        .filter((i) => i['UserId'] === user['UserId'])
                        .map((i) => `$${i['Price']}${i['Category']}`)
                        .join(', ')}
                    </p>
                    <div>{customFormatDistance(user['Create date'])}</div>
                  </UserInfo>
                </UserGroup>
              ))}
            </UserList>
          )}
          {!loading && table.getRowModel().rows.length === 0 && (
            <Empty>
              <FontAwesomeIcon icon={faCheckToSlot} style={{ fontSize: '60px' }} />
              <p>沒有符合條件的用戶</p>
            </Empty>
          )}
          {loading && (
            <>
              <Skeleton height={76} />
              <Skeleton height={76} />
              <Skeleton height={76} />
            </>
          )}
        </Card>
      </Wrapper>
    </Page>
  );
}
