import { Description, ElementStat, GodStat, Summary } from 'app/type';
import { getHorseBasicDescription, getHorseCombination, getHorseConflict } from '.';

interface HorseDescription {
  name: string;
  value: number;
  description: string;
  conflict: { type: string; content: string }[];
  combination: { type: string; content: string }[];
}

export const getGodsDescriptionArr = (data: {
  summary: Summary;
  godsStat?: Record<string, GodStat[]>;
  elementsStat?: Record<string, ElementStat[]>;
  descriptionArr: Description[];
}): Description[] => {
  const { godsStat, descriptionArr, summary } = data;
  const { gender, personType } = summary;
  const findGodKeys: string[] = Object.keys(godsStat || {});
  const filteredCommonResult = descriptionArr.filter(
    (i) => (i.personType || personType) === personType && (i.gender || gender) === gender
  );

  const finalResult = filteredCommonResult.filter((i) => {
    if (!(i.type === '十神' && findGodKeys.includes(i.name))) return false;
    switch (i.conditionType) {
      case '同現': {
        let checkNum = 0;
        Object.entries(i.conditionData).forEach(([godKey, value]) => {
          if (godsStat && godsStat[godKey]) {
            if (godsStat[godKey].reduce((acc, cur) => acc + cur.value, 0) >= value) {
              checkNum++;
            }
          }
        });
        return checkNum === Object.keys(i.conditionData).length;
      }
      case '過重': {
        let totalCount = 0;
        i.conditionData.match.forEach((godKey: string) => {
          if (godsStat && godsStat[godKey]) {
            totalCount += godsStat[godKey].reduce((acc, cur) => acc + cur.value, 0);
          }
        });
        return totalCount >= i.conditionData.total;
      }
      case '柱': {
        let checkNum = 0;
        Object.entries(i.conditionData).forEach(([position, value]) => {
          const godKey = i.name;
          if (godsStat && godsStat[godKey]) {
            if (godsStat[godKey].filter((i) => i.position === position).length >= value) {
              checkNum++;
            }
          }
        });
        return checkNum === Object.keys(i.conditionData).length;
      }
      default:
        return true;
    }
  });

  console.log('description result', descriptionArr.length, filteredCommonResult.length, finalResult.length);
  console.log(finalResult);

  return finalResult;
};

export const getHouseDescriptionArr = (data: {
  horseStats: Record<string, string[]>;
  summary: Summary;
}): HorseDescription[] => {
  const { horseStats, summary } = data;

  const arr = Object.entries(horseStats).map(([key, matches]) => ({
    name: key,
    value: matches.length,
    description: getHorseBasicDescription(key, matches.length) || '',
    conflict: getHorseConflict(summary, key),
    combination: getHorseCombination(key, matches),
  }));

  return arr;
};
