import { styled } from 'styled-components';
import { getElementsStat } from '../../util';
import { createNumberArray } from '../../util/common';

const Container = styled.div`
  display: block;
  overflow: auto;
`;
const Table = styled.table`
  font-size: 14px;
  min-width: 100%;
  padding: 0;
  background: #fff;
  border-collapse: collapse;
  table-layout: fixed;
  thead th {
    text-align: left;
  }

  th,
  td {
    padding: 16px 8px;
    background-color: #fff;
  }

  tr:nth-child(2n-1) td {
    background-color: #f3f6f6;
  }
`;

const StickyCol = styled.td`
  position: sticky;
  left: 0;

  & > span {
    display: block;
    min-width: 80px;
  }
`;

const ElementCol = styled.td<{ diff: number }>`
  color: ${(props: any) => (props.diff > 0 ? 'green' : props.diff < 0 ? 'red' : 'black')};
`;

export default function YearsElementStat(props: any) {
  const { summary, selectedYear } = props;
  const yearsArr: number[] = createNumberArray(10, selectedYear);
  const eleArr = ['金', '木', '水', '火', '土'];

  const basicStat = getElementsStat(summary) || {};
  const basicStatTotal = Object.values(basicStat).reduce((acc, i) => acc + i.total, 0);
  const yearStats: any[] = [];
  const yearStatTotals: any[] = [];

  yearsArr.forEach((y) => {
    const yearStat = getElementsStat(summary, '流年', y) || {};
    const statTotal = Object.values(yearStat).reduce((acc, i) => acc + i.total, 0);
    yearStats.push(yearStat);
    yearStatTotals.push(statTotal);
  });

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <StickyCol as="th">
              <span>五行(原盤)</span>
            </StickyCol>
            {yearsArr.map((y) => (
              <th key={y}>{y}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {eleArr.map((ele) => (
            <tr key={ele}>
              <StickyCol>
                <span>
                  {ele}({basicStat[ele].total})
                </span>
              </StickyCol>
              {yearsArr.map((y, index) => (
                <ElementCol diff={yearStats[index][ele].total - basicStat[ele].total} key={y}>
                  {yearStats[index][ele].total}
                </ElementCol>
              ))}
            </tr>
          ))}
          <tr key="total">
            <StickyCol>
              <span>總（{basicStatTotal})</span>
            </StickyCol>
            {yearStatTotals.map((t, index) => (
              <td key={index}>{t}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}
