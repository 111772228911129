import { styled } from 'styled-components';
import { formatDate } from '../../util/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { updateData } from 'app/services/api';
import moment from 'moment';
import { addHistory } from 'app/util/history';

const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FieldLabel = styled.div`
  /* flex: 0 0 90px; */
  font-weight: bold;
`;

const FieldBody = styled.div<{ maxWidth?: string }>`
  flex: 1 1 ${({ maxWidth }): string => maxWidth || 'auto'};
  max-width: ${({ maxWidth }): string => maxWidth || '780px'};
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-line;
  font-size: 14px;
`;

const EditButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 10px;
  color: #000;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  flex: 1;
  margin: 0;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

const Options = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const Textarea = styled(TextareaAutosize)`
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

const OptionButton = styled.button<{ active?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: ${(p) => (p.active ? '#5b4f47' : '#fff')};
  color: ${(p) => (p.active ? '#fff' : '#000')};
  flex: 1;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  padding: 14px 16px;
  border-radius: 20px;
  flex: 1;
  background-color: #5b4f47;
  color: #fff;
  border: none;
`;

export default function User(props: any) {
  const { data, refetch, setLoading } = props;
  const { user } = data;
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<any>({});

  const onChangeFormData = (key: string, value: any) => {
    setFormData((i: any) => ({ ...i, [key]: value }));
  };
  const columns = [
    { key: '八字', label: '八字', value: user['八字'] ? formatDate(user['八字']) : 'N/a', type: 'date' },
    { key: '性別', label: '性別', value: user['性別'], type: 'select', options: ['男', '女'] },
    { key: 'PIC', label: 'PIC', value: user['PIC'], type: 'select', options: ['Elise', 'Shirley'] },
    { key: 'From', label: 'From', value: user['From'], type: 'select', options: ['IG', 'HM'] },
    { key: '背景資料', label: '背景資料', value: user['背景資料'] || 'N/a', type: 'textarea' },
    {
      key: 'Status',
      label: '狀態',
      value: user['Status'] || 'N/a',
      type: 'select',
      options: ['待簡批', '待答覆問題', '已回覆'],
    },
    { key: '簡批', label: '簡批', value: user['簡批'] || 'N/a', type: 'textarea' },
  ];

  const renderInput = (i: { key: string; type: string; options?: string[] }) => {
    if (i.type === 'date') {
      return (
        <Input
          placeholder="yyyymmddhhmm"
          type="number"
          pattern="[0-9]*"
          value={formData[i.key]}
          onChange={(e) => onChangeFormData(i.key, e.target.value)}
        />
      );
    }
    if (i.type === 'number') {
      return (
        <Input
          type="number"
          pattern="\d*"
          value={formData[i.key]}
          onChange={(e) => onChangeFormData(i.key, e.target.value)}
        />
      );
    }
    if (i.type === 'text') {
      return <Input value={formData[i.key]} onChange={(e) => onChangeFormData(i.key, e.target.value)} />;
    }
    if (i.type === 'textarea') {
      return <Textarea minRows={2} value={formData[i.key]} onChange={(e) => onChangeFormData(i.key, e.target.value)} />;
    }
    if (i.type === 'select') {
      return (
        <Options>
          {(i.options || []).map((value: string) => (
            <OptionButton
              type="button"
              key={value}
              active={value === formData[i.key]}
              onClick={() => onChangeFormData(i.key, value)}
            >
              {value}
            </OptionButton>
          ))}
        </Options>
      );
    }
    return;
  };

  const onSubmit = async () => {
    const { UserId, ...rest } = formData;
    if (UserId) {
      setLoading(true);
      const data = {
        ...rest,
        八字: moment(formData['八字'], 'YYYYMMDDHmm').format('MM/DD/YYYY H:mm:ss'),
      };
      await updateData(UserId, data);
      refetch();
    }
  };

  useEffect(() => {
    const result = {
      UserId: user['UserId'],
      八字: formatDate(user['八字'], true),
      性別: user['性別'],
      PIC: user['PIC'],
      Status: user['Status'],
      From: user['From'],
      背景資料: user['背景資料'],
      簡批: user['簡批'],
    };
    setFormData(result);
    addHistory(user);
  }, [user]);

  return (
    <Container>
      <EditButton onClick={() => setEditMode(!editMode)}>
        <FontAwesomeIcon icon={faEdit} />
      </EditButton>
      {columns.map((i, index) => (
        <FieldContainer key={index}>
          <FieldLabel>{i.label}:</FieldLabel>
          {!editMode && <FieldBody>{i.value}</FieldBody>}
          {editMode && <FieldBody>{renderInput(i)}</FieldBody>}
        </FieldContainer>
      ))}
      {editMode && (
        <SubmitButton type="submit" onClick={onSubmit}>
          更新
        </SubmitButton>
      )}
    </Container>
  );
}
