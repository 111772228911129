import { Combination, Word, YearWords } from 'app/type';
import { getBigYearBySmallYear, getCombinationChanges } from '.';
import { elementMap, godsRatio, godsTerm } from 'app/config/constants';

export const getCombinationFromHTML = (html: string, words: Word[]): Combination[] => {
  const result: any[] = [];
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const orderArr = ['時', '日', '月', '年'];

  const upEle = tempElement.querySelectorAll('.toprelationdiv > .relationrow');
  const downEle = tempElement.querySelectorAll('.bottomrelationdiv > .relationrow');
  // @ts-ignore
  [...upEle, ...downEle].forEach((ele) => {
    const group = ele.parentNode.className.includes('bottomrelationdiv') ? '地支' : '天干';
    const classList = [...ele.classList];
    let start = 0;
    let end = 0;
    let selectedIndexes = [];

    // TBC
    if (classList.includes('baziprefix_12')) {
      start = 0;
    } else if (classList.includes('baziprefix_36')) {
      start = 1;
    } else if (classList.includes('baziprefix_60')) {
      start = 2;
    }

    if (classList.includes('cellsize_24')) {
      end = start + 1;
    } else if (classList.includes('cellsize_48')) {
      end = start + 2;
    } else if (classList.includes('cellsize_72')) {
      end = start + 3;
    }
    selectedIndexes.push(start);

    // handle on select middle
    if (ele.getElementsByClassName('relationrow').length) {
      const nestedEle = ele.getElementsByClassName('relationrow')[0];
      const nestedClassList = [...nestedEle.classList];
      // TBC
      if (nestedClassList.includes('baziprefix_33') || nestedClassList.includes('baziprefix_50')) {
        selectedIndexes.push(start + 1);
      } else if (nestedClassList.includes('baziprefix_66')) {
        selectedIndexes.push(start + 2);
      }
    }
    selectedIndexes.push(end);

    const combination = {
      group,
      words: selectedIndexes.map((selectedIndex) =>
        words.find((word) => word.position === orderArr[selectedIndex] && word.group === group)
      ),
      name: ele.getElementsByTagName('span')[0].textContent,
    } as Combination;

    result.push({
      ...combination,
      change: getCombinationChanges(combination),
    });
  });

  return result;
};

export const getYearsDataFromHTML = (html: string, originalWords: Word[]): any => {
  const bigWords = getYearWords(html, '.bigfortunerow .fortunecolumn', '大運');
  const smallWords = getYearWords(html, '.smallfortunerolling > .fortunecolumn', '流年');

  const big = getFullYearData(html, '.bigfortunerow .fortunecolumn', originalWords, bigWords, smallWords);
  const small = getFullYearData(html, '.smallfortunerolling > .fortunecolumn', originalWords, bigWords, smallWords);

  return {
    big,
    small,
  };
};

const getYearWords = (html: string, query: string, position: string): YearWords[] => {
  const records: YearWords[] = [];
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  tempElement.querySelectorAll(query).forEach((ele: any) => {
    const year = ele.querySelector('.yeardetail > .textleft')?.textContent;
    const age = ele.querySelector('.yeardetail > .textright')?.textContent;
    const words: Word[] = [];

    // get words
    ele.querySelectorAll('.fdetail').forEach((wordEle: any, index: number) => {
      const wordValue = wordEle.querySelector('.fdetailword')?.textContent || '';
      // @ts-ignore
      const godsStrings = [...wordEle.querySelectorAll('.fsmalldetail')].map((i) => godsTerm[i.textContent]);
      const word = {
        group: index === 0 ? '天干' : '地支',
        position,
        value: wordValue,
        elements: elementMap[wordValue],
        gods: godsStrings.map((i, index) => ({ type: i, value: godsRatio[godsStrings.length][index] })),
        allowOverride: true,
      };
      words.push(word);
    });

    records.push({
      year: parseInt(year, 10),
      age: parseInt(age, 10),
      words,
    });
  });

  return records.sort((a, b) => a.year - b.year);
};

const getFullYearData = (
  html: string,
  query: string,
  originalWords: Word[],
  bigYears: YearWords[],
  smallYears: YearWords[]
): any[] => {
  const records: any[] = [];
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  tempElement.querySelectorAll(query).forEach((ele: any) => {
    const year = parseInt(ele.querySelector('.yeardetail > .textleft')?.textContent, 10);
    const age = parseInt(ele.querySelector('.yeardetail > .textright')?.textContent, 10);

    // get Combination
    const combination: Combination[] = [];
    ele.querySelectorAll('.fortunemergeword').forEach((combinationEle: any) => {
      // @ts-ignore
      const group = combinationEle?.parentNode?.className?.includes('fdetail') ? '天干' : '地支';
      const name = getStringBetweenTags(combinationEle.title)?.trim();
      const foundMatch = combinationEle.title.split('<br />').map((i: string) => i.trim()) || [];
      const foundWords: Word[] = [];
      foundMatch.shift();
      foundMatch.forEach((matchText: string) => {
        if (/^.柱$/.test(matchText)) {
          foundWords.push(originalWords.find((i) => i.position === matchText.charAt(0) && i.group === group) as Word);
        } else if (matchText === '大運') {
          const bigYear = getBigYearBySmallYear(year, bigYears);
          if (bigYear) {
            foundWords.push(bigYear.words.find((i) => i.position === matchText && i.group === group) as Word);
          }
        } else if (matchText === '流年') {
          const smallYear = smallYears.find((i) => year === i.year);
          if (smallYear) {
            foundWords.push(smallYear.words.find((i) => i.position === matchText && i.group === group) as Word);
          }
        }
      });
      const data = {
        group,
        words: foundWords,
        name,
      } as Combination;

      combination.push({
        ...data,
        // @ts-ignore
        change: getCombinationChanges(data),
      });
    });

    records.push({
      year,
      age,
      combination,
    });
  });

  return records;
};

function getStringBetweenTags(str: string) {
  const regex = /<b>(.*?)<\/b>/;
  const match = str.match(regex);
  return match ? match[1] : null;
}
