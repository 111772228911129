import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getData, getReport } from '../../services/api';
import { useParams, useSearchParams } from 'react-router-dom';
import DNA from '../../components/DNA';
import ElementStat from '../../components/ElementStat';
import Card from '../../components/Card';
import HorseStat from '../../components/HorseStat';
import GodsStat from '../../components/GodsStat';
import Header from 'app/components/Header';
import LogoImage from 'asset/logo.png';
import { getSummary } from 'app/util';
import YearsElementStat from 'app/components/YearsElementStat';
import Description from 'app/components/Description';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { Field, Form, Input, Label, OptionButton, Options, SubmitButton } from 'app/components/FormElement';
import moment from 'moment';
import Dialog from 'app/components/Dialog';

const Page = styled.div``;

const Logo = styled.img`
  height: auto;
  width: 50vw;
  max-width: 150px;
  border-radius: 32px;
`;

const Loading = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
  background-color: #f6f4ee;
  @media (max-width: 768px) {
    padding: 8px 4px;
  }
`;

export function RecordPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<any>(null);
  const [passCode, setPassCode] = useState<string>('');
  const { id } = useParams();
  console.log('id', id);

  const onSubmit = () => {
    console.log('passCode', passCode);
  };

  if (loading) {
    return (
      <Page>
        <Wrapper>
          <Card>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Card>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      {!fetchedData && (
        <Dialog title="請填妥以下資料作確認身份" hideButtons>
          <label>
            <span style={{ marginRight: '8px' }}>出身月日(MMDD)</span>
            <Input
              type="number"
              pattern="\d*"
              placeholder="eg. 0115"
              value={passCode}
              onChange={(e) => setPassCode(e.target.value)}
            />
          </label>
          <div style={{ display: 'flex', gap: '16px' }}>
            <SubmitButton disabled={passCode.length !== 4} onClick={onSubmit}>
              確定
            </SubmitButton>
          </div>
        </Dialog>
      )}

      <Wrapper></Wrapper>
    </Page>
  );
}
