import Card from 'app/components/Card';
import { createUser, getLatestUsers } from 'app/services/api';
import { customFormatDistance, formatDate } from 'app/util/common';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import Header from 'app/components/Header';
import TextareaAutosize from 'react-textarea-autosize';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Dialog from 'app/components/Dialog';
import { UserList } from 'app/components/UserList';

const Page = styled.div``;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  padding: 16px;
  @media (max-width: 768px) {
    padding: 8px 4px;
  }
  background-color: #f6f4ee;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Field = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Label = styled.label`
  min-width: 60px;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  flex: 1;
  margin: 0;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

const Options = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const OptionButton = styled.button<{ active?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: ${(p) => (p.active ? '#5b4f47' : '#fff')};
  color: ${(p) => (p.active ? '#fff' : '#000')};
  flex: 1;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  padding: 14px 16px;
  border-radius: 20px;
  flex: 1;
  background-color: #5b4f47;
  color: #fff;
  border: none;
`;

const UserGroup = styled.button`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  min-height: 48px;
  border-radius: 4px;
  padding: 16px;
  border: none;
  background-color: #fff;
  color: #5b4f47;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  font-size: 12px;
`;

const Refresh = styled(FontAwesomeIcon)`
  padding: 4px;
  cursor: pointer;
`;

const Textarea = styled(TextareaAutosize)`
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #e0e0e0;
`;

interface Data {
  birth?: string;
  gender?: string;
  from?: string;
  bg?: string;
  pic?: string;
}

export function CreateUserPage() {
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data>({ gender: '男', from: 'IG', pic: 'Elise' });
  const [users, setUsers] = useState<any[]>([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [dUser, setDUser] = useState<any>({});
  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (moment(data.birth, 'YYYYMMDDHmm', true).isValid() && data.from && data.gender) {
      const eight = moment(data.birth, 'YYYYMMDDHmm').format('MM/DD/YYYY H:mm:ss');
      const foundUser = users.find((i) => i['八字'] === eight);
      if (foundUser) {
        setDUser(foundUser);
        setOpenDuplicate(true);
      } else {
        onCreateUser();
      }
    } else {
      setOpenWarning(true);
    }
  };

  const onCreateUser = async () => {
    setCreating(true);
    const resp = await createUser(data);
    if (resp.Rows) {
      window.location.href = `/dashboard?id=${resp.Rows[0]['UserId']}`;
      setCreating(false);
    }
  };

  const onChangeData = (key: string, value: any) => {
    setData((i) => ({ ...i, [key]: value }));
  };

  const genderOptions = ['男', '女'];
  const fromOptions = ['IG', 'HM'];
  const picOptions = ['Elise', 'Shirley'];

  async function fetchData() {
    setLoading(true);
    const res = await getLatestUsers();
    setUsers(res);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page>
      <Header back />
      <Wrapper>
        <Card title="創建用戶">
          <Form onSubmit={onSubmit}>
            <Field>
              <Label>八字:</Label>
              <Input
                placeholder="yyyymmddhhmm"
                type="number"
                pattern="[0-9]*"
                value={data?.birth}
                onChange={(e) => onChangeData('birth', e.target.value)}
              />
            </Field>
            <Field>
              <Label>性別:</Label>
              <Options>
                {genderOptions.map((i) => (
                  <OptionButton
                    type="button"
                    key={i}
                    active={i === data?.gender}
                    onClick={() => onChangeData('gender', i)}
                  >
                    {i}
                  </OptionButton>
                ))}
              </Options>
            </Field>
            <Field>
              <Label>來自:</Label>
              <Options>
                {fromOptions.map((i) => (
                  <OptionButton type="button" key={i} active={i === data?.from} onClick={() => onChangeData('from', i)}>
                    {i}
                  </OptionButton>
                ))}
              </Options>
            </Field>
            <Field>
              <Label>PIC:</Label>
              <Options>
                {picOptions.map((i) => (
                  <OptionButton type="button" key={i} active={i === data?.pic} onClick={() => onChangeData('pic', i)}>
                    {i}
                  </OptionButton>
                ))}
              </Options>
            </Field>
            <Field>
              <Label>背景:</Label>
              <Textarea minRows={2} value={data?.bg} onChange={(e) => onChangeData('bg', e.target.value)} />
            </Field>
            {creating ? <p>創建中</p> : <SubmitButton type="submit">創建</SubmitButton>}
          </Form>
        </Card>
        <Card title="最近開創用戶" trailing={<Refresh icon={faRotate} onClick={fetchData} />}>
          {!loading && <UserList users={users} />}
          {loading && (
            <>
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
            </>
          )}
        </Card>
      </Wrapper>
      {openWarning && <Dialog onClose={() => setOpenWarning(false)} description="未完成表格，請確定八字格式是正確" />}
      {openDuplicate && (
        <Dialog
          onClose={() => setOpenDuplicate(false)}
          closeLabel="取消"
          description="已有重覆的八字用戶，仍要開創?"
          onConfirm={() => {
            onCreateUser();
            setOpenDuplicate(false);
          }}
          confirmLabel="仍要開創"
        >
          <UserGroup
            key={dUser['UserId']}
            onClick={() => {
              window.location.href = `/dashboard?id=${dUser['UserId']}`;
            }}
          >
            <UserInfo>
              <p style={{ fontWeight: 600, fontSize: '14px' }}>
                <span style={{ color: dUser['性別'] === '男' ? '#6d96be' : '#fd8587' }}>{dUser['性別']}</span>{' '}
                {formatDate(dUser['八字'])}
              </p>
              <p>{dUser['背景資料']}</p>
            </UserInfo>
            <UserInfo style={{ alignItems: 'flex-end', opacity: 0.8 }}>
              <p>{dUser['Status']}</p>
              <div>{customFormatDistance(dUser['Create date'])}</div>
            </UserInfo>
          </UserGroup>
        </Dialog>
      )}
    </Page>
  );
}
