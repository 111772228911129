'use client';

import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  title?: string;
  description?: string;
  confirmLabel?: string;
  closeLabel?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  hideButtons?: boolean;
}

export const DialogContainer = styled(motion.div).attrs({
  variants: {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide',
  transition: {
    duration: 0.2,
  },
})`
  position: fixed;
  z-index: 1000;
  inset: 0;
  margin: auto;
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const DialogCloseButton = styled.button.attrs({ name: 'cross', shape: 'none', size: 20 })`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const DialogBody = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 16px;
  gap: 8px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
`;

export const DialogActions = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 14px;
`;

const Button = styled.button`
  padding: 14px 16px;
  border-radius: 20px;
  min-width: 100px;
  background-color: #5b4f47;
  color: #fff;
  border: none;
`;

export default function Dialog(props: PropsWithChildren<Props>): JSX.Element {
  const {
    title = '通知',
    confirmLabel = '確定',
    closeLabel = 'OK',
    description,
    onClose,
    onConfirm,
    hideButtons = false,
    children,
  } = props;

  return (
    <DialogContainer>
      <DialogBody>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {children}
        {!hideButtons && (
          <DialogActions>
            {onConfirm && <Button onClick={onConfirm}>{confirmLabel}</Button>}
            <Button onClick={onClose}>{closeLabel}</Button>
          </DialogActions>
        )}
      </DialogBody>
    </DialogContainer>
  );
}
