import { Invoice, User } from 'app/type';
import moment from 'moment';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || '';
const CHAT_API_DOMAIN = process.env.REACT_APP_CHAT_API_DOMAIN || '';

export async function getData(id: string): Promise<any> {
  const response = await fetch(`${API_DOMAIN}?id=${id}&format=json`, {
    method: 'GET',
  });

  const data = await response.json();
  return data;
}

export async function getReport(formData: any): Promise<any> {
  const response = await fetch(`${API_DOMAIN}/report?gender=${formData.gender}&birth=${formData.birth}`, {
    method: 'GET',
  });

  const data = await response.json();
  return data;
}

export async function createUser(data: any): Promise<any> {
  const formData = new FormData();
  formData.append('八字', moment(data.birth, 'YYYYMMDDHmm').format('MM/DD/YYYY H:mm:ss'));
  formData.append('性別', data.gender);
  formData.append('from', data.from);
  formData.append('Month', moment().format('YYYY-MM'));
  formData.append('背景資料', data.bg || '');
  formData.append('PIC', data.pic || '');

  const response = await fetch(`${API_DOMAIN}/user`, {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  return result;
}

export async function updateData(userId: string, data: any): Promise<any> {
  const formData = new FormData();
  formData.append('UserId', userId);
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  const response = await fetch(`${API_DOMAIN}/user-update`, {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  return result;
}

export async function getChat(question: string): Promise<any> {
  const raw = JSON.stringify({
    question,
  });

  const response = await fetch(CHAT_API_DOMAIN, {
    method: 'POST',
    body: raw,
    // redirect: 'follow',
  });

  const data = await response.json();
  return data.message.content;
}

export async function getLatestUsers(limit = 5): Promise<User[]> {
  const response = await fetch(`${API_DOMAIN}/user/latest?limit=${limit}`, {
    method: 'GET',
  });

  const data = await response.json();
  return data.users;
}

export async function getAllInvoices(): Promise<Invoice[]> {
  const response = await fetch(`${API_DOMAIN}/invoice`, {
    method: 'GET',
  });

  const data = await response.json();
  return data.result;
}

export async function createInvoice(userId: string, data: any): Promise<any> {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value as string);
  });
  formData.append('UserId', userId);

  const response = await fetch(`${API_DOMAIN}/invoice`, {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  return result;
}
