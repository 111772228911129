// @ts-nocheck
import { elementMap, godsRatio, godsTerm } from '../config/constants';
import { Combination, ElementStat, GodStat, Summary, Word, YearWords } from '../type';
import { getCombinationFromHTML, getYearsDataFromHTML } from './dna';

export const getCombinationChanges = (data: Omit<Combination, 'change'>) => {
  // https://www.powerluck.tw/knowledge/104-698-1

  // 地支三會
  if (/^.局成化$/.test(data.name) && data.group === '地支') {
    // (1）寅卯辰為木
    //（2）巳午未為火
    //（3）申酉戌為金
    //（4）亥子丑為水
    //（5）辰戌丑未為土

    let element = '';
    if (data.words.find((i) => i.value === '卯')) element = '木';
    if (data.words.find((i) => i.value === '午')) element = '火';
    if (data.words.find((i) => i.value === '酉')) element = '金';
    if (data.words.find((i) => i.value === '子')) element = '水';
    if (data.words.find((i) => i.value === '戌')) element = '土';
    return {
      override: true,
      element: {
        type: element,
        value: 100 * 2,
      },
      god: { type: godsTerm[data.name.charAt(0)], value: 2 },
      order: 10,
    };
  }

  //地支三合、半合
  if ((/^會.成化$/.test(data.name) || /^半.成化$/.test(data.name)) && data.group === '地支') {
    const matchArr = [
      ['申', '子', '辰'],
      ['巳', '酉', '丑'],
      ['寅', '午', '戌'],
      ['卯', '未', '亥'],
    ];
    const matchElement = ['水', '金', '火', '木'];
    const allWord = data.words.map((i) => i.value).join('');

    for (let i = 0; i < matchArr.length; i++) {
      let count = 0;
      matchArr[i].forEach((item) => {
        count += allWord.includes(item) ? 1 : 0;
      });
      if (count === 3) {
        return {
          override: true,
          element: {
            type: matchElement[i],
            value: 100 * 1.5,
          },
          god: { type: godsTerm[data.name.charAt(1)], value: 1.5 },
          order: 20,
        };
      } else if (count === 2) {
        return {
          override: true,
          element: {
            type: matchElement[i],
            value: 100 * 1.2,
          },
          god: { type: godsTerm[data.name.charAt(1)], value: 1.2 },
          order: 20,
        };
      }
    }
  }

  // 天干五合
  if (/^合.成化$/.test(data.name) && data.group === '天干') {
    // Ⅰ、甲己合土
    // Ⅱ、乙庚合金
    // Ⅲ、丙辛合水
    // Ⅳ、丁壬合木
    // Ⅴ、戊癸合火
    let element = '';
    if (data.words.find((i) => i.value === '甲') && data.words.find((i) => i.value === '己')) element = '土';
    if (data.words.find((i) => i.value === '乙') && data.words.find((i) => i.value === '庚')) element = '金';
    if (data.words.find((i) => i.value === '丙') && data.words.find((i) => i.value === '辛')) element = '水';
    if (data.words.find((i) => i.value === '丁') && data.words.find((i) => i.value === '壬')) element = '木';
    if (data.words.find((i) => i.value === '戊') && data.words.find((i) => i.value === '癸')) element = '火';

    return {
      order: 50,
      override: true,
      element: {
        type: element,
        value: 60,
      },
      god: { type: godsTerm[data.name.charAt(1)], value: 1 },
    };
  }
  // 地支六合
  if (/^合.成化$/.test(data.name) && data.group === '地支') {
    // Ⅰ、子丑合土
    // Ⅱ、寅亥合木
    // Ⅲ、卯戌合火
    // Ⅳ、辰酉合金
    // Ⅴ、巳申合水
    // Ⅵ、午未合火
    let element = '';
    if (data.words.find((i) => i.value === '子') && data.words.find((i) => i.value === '丑')) element = '土';
    if (data.words.find((i) => i.value === '寅') && data.words.find((i) => i.value === '亥')) element = '木';
    if (data.words.find((i) => i.value === '卯') && data.words.find((i) => i.value === '戌')) element = '火';
    if (data.words.find((i) => i.value === '辰') && data.words.find((i) => i.value === '酉')) element = '金';
    if (data.words.find((i) => i.value === '巳') && data.words.find((i) => i.value === '申')) element = '水';
    if (data.words.find((i) => i.value === '午') && data.words.find((i) => i.value === '未')) element = '火';
    return {
      order: 50,
      override: true,
      element: {
        type: element,
        value: 100,
      },
      god: { type: godsTerm[data.name.charAt(1)], value: 1 },
    };
  }
  // 不化
  if (/^半.不化$/.test(data.name) || /^合.不化$/.test(data.name)) {
    return {
      order: 60,
      override: false,
      scale: { element: 0.9, god: 0.9 },
    };
  }

  return null;
};

export const getCombinationChangeByWord = (
  word: Word,
  summary: Summary,
  withYear?: '大運' | '流年',
  selectYear?: number
) => {
  const { combination, years } = summary;
  let founds = [];
  let foundsFromBigYear = [];
  let foundsFromSmallYear = [];

  founds = combination.filter((c) =>
    c.words.find((w) => w.value === word.value && w.group === word.group && w.position === word.position)
  );

  if (withYear === '大運' && selectYear) {
    const bigYearCombination = years.big.find((i) => i.year === selectYear).combination;
    foundsFromBigYear = bigYearCombination.filter((c) =>
      c.words.find((w) => w.value === word.value && w.group === word.group && w.position === word.position)
    );
  } else if (withYear === '流年' && selectYear) {
    const bigYearCombination = getBigYearBySmallYear(selectYear, years.big).combination;
    const smallYearCombination = years.small.find((i) => i.year === selectYear).combination;
    foundsFromBigYear = bigYearCombination.filter((c) =>
      c.words.find((w) => w.value === word.value && w.group === word.group && w.position === word.position)
    );
    foundsFromSmallYear = smallYearCombination.filter((c) =>
      c.words.find((w) => w.value === word.value && w.group === word.group && w.position === word.position)
    );
  }

  // sorting by order
  return [...founds, ...foundsFromBigYear, ...foundsFromSmallYear].sort(
    (a, b) => (a?.change?.order || 999) - (b?.change?.order || 999)
  )[0];
};

// @TODO split into process and getData from html
export const getSummary = (html: string, user: any): Summary => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const rows = tempElement.querySelectorAll('.detailrow');
  // @ts-ignore
  const wordValues = [...rows].map((row) =>
    [...row.getElementsByTagName('span')].map((i) => i.textContent.trim()).reverse()
  );
  const details = tempElement.querySelectorAll('.detailright');
  // @ts-ignore
  const gods = [...details].map((detail) =>
    [...detail.querySelectorAll('.smalldetail')].map((i) => i.textContent.trim()).filter(Boolean)
  );
  const godsUp = [gods[3], gods[2], gods[1], gods[0]];
  const godsDown = [gods[7], gods[6], gods[5], gods[4]];
  const p = tempElement.getElementsByClassName('persontype')[0];

  const words: Word[] = [];
  const indexMap = ['年', '月', '日', '時'];

  // add 天干
  wordValues[0].forEach((wordValue, index) => {
    const godsStrings = godsUp[index].map((i) => godsTerm[i]);
    const result = {
      group: '天干',
      position: indexMap[index],
      value: wordValue,
      elements: elementMap[wordValue],
      gods: godsStrings.map((i, index) => ({ type: i, value: godsRatio[godsStrings.length][index] })),
      allowOverride: indexMap[index] === '日' ? false : true,
    };
    words.push(result);
  });

  // add 地支
  wordValues[1].forEach((wordValue, index) => {
    const godsStrings = godsDown[index].map((i) => godsTerm[i]);
    const result = {
      group: '地支',
      position: indexMap[index],
      value: wordValue,
      elements: elementMap[wordValue],
      gods: godsStrings.map((i, index) => ({ type: i, value: godsRatio[godsStrings.length][index] })),
      allowOverride: true,
    };
    words.push(result);
  });

  const summary = {
    personType: p.options[p.selectedIndex].text,
    gender: user['性別'],
    age: user['年齡'],
    words,
    combination: getCombinationFromHTML(html, words),
    years: getYearsDataFromHTML(html, words),
  };

  return summary;
};

export const getElementsStat = (
  summary: Summary,
  withYear?: '大運' | '流年',
  year?: number
): Record<string, ElementStat> => {
  if (!summary) return null;

  const elementMap = {
    金: { 天干: 0, 地支: 0, total: 0 },
    木: { 天干: 0, 地支: 0, total: 0 },
    水: { 天干: 0, 地支: 0, total: 0 },
    火: { 天干: 0, 地支: 0, total: 0 },
    土: { 天干: 0, 地支: 0, total: 0 },
  };

  summary.words
    .filter((word) => word.group === '天干')
    .forEach((word) => {
      // 合化checking
      const selectedCombination = getCombinationChangeByWord(word, summary, withYear, year);
      if (selectedCombination?.change && word.allowOverride) {
        // 合化 update
        const { override, element, scale } = selectedCombination.change;
        if (override) {
          elementMap[element.type][word.group] += element.value;
        } else {
          word.elements.forEach((element) => {
            elementMap[element.type][word.group] += element.value * scale.element;
          });
        }
      } else {
        word.elements.forEach((element) => {
          elementMap[element.type][word.group] += element.value;
        });
      }
    });

  summary.words
    .filter((word) => word.group === '地支')
    .forEach((word) => {
      // 合化checking
      const selectedCombination = getCombinationChangeByWord(word, summary, withYear, year);

      if (selectedCombination?.change && word.allowOverride) {
        // 合化 update
        const { override, element, scale } = selectedCombination.change;
        if (override) {
          const newValue = word.position === '月' ? element.value * 2 : element.value;
          elementMap[element.type][word.group] += newValue;
        } else {
          word.elements.forEach((element) => {
            const newValue = (word.position === '月' ? element.value * 2 : element.value) * scale.element;
            elementMap[element.type][word.group] += newValue;
          });
        }
      } else {
        word.elements.forEach((element) => {
          const newValue = word.position === '月' ? element.value * 2 : element.value;
          elementMap[element.type][word.group] += newValue;
        });
      }
    });

  Object.keys(elementMap).forEach((key) => {
    const bonus = elementMap[key].天干 > 0 && elementMap[key].地支 === 0 ? -30 : 0;
    elementMap[key].total = elementMap[key].天干 + elementMap[key].地支 + bonus;
  });

  return elementMap;
};

export const getElementDescription = (key, value) => {
  switch (key) {
    case '金': {
      if (value >= 200) return '過度重情重義，傲氣高愛逞強，聽不進人勸，容易跟人有意氣之爭。';
      if (value >= 120) return '重義氣，願意捨己助友，為朋友兩肋插刀。';
      if (value > 0) return '對朋友的情義不足，較不願意借錢給朋友。';
      return '不重道義，不願意幫助朋友，尤其是金錢相關的事物，更是吝嗇。';
    }
    case '木': {
      if (value >= 200) return '太多耐心、過度仁慈、容易被騙。有時候會轉換為跟人家借東西拖欠不還... 變成另類的持久...';
      if (value >= 120) return '耐心剛剛好，不會太多也不會太少。';
      if (value > 0) return '稍微有一點耐性，但是還不夠，需要再加強。';
      return '缺少恒心、毅力，容易虎頭蛇尾、有始無終。';
    }
    case '水': {
      if (value >= 200)
        return '天資聰敏，能言善辯，但有時候會太過度依賴與天俱來的天賦，而忽略了後天能力的養成，久而久之容易形成怠惰、懶散的性格。如果沒有因為外在壓力的逼迫，不會努力工作。';
      if (value >= 120) return '冷靜、機智、反應快、口才好。理性多於感性！';
      if (value > 0) return '機智反應不足，容易被騙。';
      return '不喜歡費神思考，傾向依賴他人幫自己安排。';
    }
    case '火': {
      if (value >= 200) return '過度有孝禮之心跟行動力，有時候會變成愛慕虛榮、愛面子、衝動、意氣用事。';
      if (value >= 120) return '注重禮貌、禮儀，具有孝順的人格特質。不過有時個性會稍嫌急躁...';
      if (value > 0) return '稍微有一點孝心或行動力，但是還不夠，需要再加強。';
      return '沒有孝心、奮鬥心、行動力，禮貌表現不佳。';
    }
    case '土': {
      if (value >= 200)
        return '性格溫厚，人品穩重，待人誠實，講信守譽。對於上頭交辦之任務，均能圓滿達成。務實的處事態度，加上腳踏實地，深受大家喜愛。不過，也因為憨厚個性，太容易相信他人，導致常吃虧、上當受騙。';
      if (value >= 120)
        return '做人處事，講究誠信，會盡力做好份內的事。在工作崗位上盡忠職守，是老闆身邊最稱職的左右手。';
      if (value > 0) return '信守承諾的能力還需加強。建議不要隨便承諾自己做不到的事，減少失信之後後續的爭執和困擾。';
      return '有出爾反爾、信口開河的特質，在誠信上容易有負評。';
    }
  }
};

export const getHorse = (summary: Summary): Record<string, string[]> => {
  if (!summary) return null;
  const fullMap = {
    馬: [],
    花: [],
    庫: [],
  };

  summary.words.forEach((word, index) => {
    if ('寅申巳亥'.includes(word.value)) fullMap['馬'].push(word.position);
    if ('子午卯酉'.includes(word.value)) fullMap['花'].push(word.position);
    if ('辰戌丑未'.includes(word.value)) fullMap['庫'].push(word.position);
  });

  return fullMap;
};

export const getHorseBasicDescription = (key, value) => {
  switch (key) {
    case '馬': {
      if (value === 0) return '沒有衝勁，不適合當業務，適合內勤工作，衝勁弱。';
      if (value === 1) return '好動，適合做業務，願意跑，愛跑。但跑不遠，靈機應變，不受拘束。';
      if (value === 2)
        return '喜橫衝直撞。旅遊，搬遷，變動，有行動力，執行力，好動。好動閒不住，不受拘束，業務高手，不常留屋內，注意車關。';
      if (value === 3)
        return '藝高膽大，勞碌奔波。沒有合，只會亂衝，不懂收成，為錢忙來忙去，什麼都要闖，多車關，居無定所，四處跑。';
      if (value >= 4)
        return '家裡待不住，常往外跑，一出去不知回家。設定目標，馬不停蹄，藝高人膽大，什麼都要闖，多車關，居無定所，四處跑。';
      break;
    }
    case '花': {
      if (value === 0) return '很嚴肅，臉很臭，異性緣差，人際關係不良。';
      if (value === 1) return '有人緣，人緣佳，早熟，貌美，異性緣還可以。';
      if (value === 2)
        return '真有人緣，早熟，漂亮貌美，早熟，異性緣還不錯。外型亮眼、美麗，懂得人情世故，心智年齡比同年齡的人還要來的更加敏銳，喜歡幫助他人，顧及別人的感受和需要，因此深受大家的喜愛，頗有異性緣。';
      if (value === 3) return '異性緣重，早熟，適公關，人緣佳，異性緣特別好，貌美，早熟，文才風流，善應對 ( 好爭辯 )。';
      if (value >= 4)
        return '超有人緣，但注意道德觀。或超沒人緣，因過度自戀，孤芳自賞。爛桃花，異性緣特別好，處處留情，為求圓融而不惜說謊，早熟，不太愛理人，雙重人格。';
      break;
    }
    case '庫': {
      if (value === 0) return '錢財守不住，散財童子，很節儉，財來財去。錢不是自己的。';
      if (value === 1) return '很節儉，疼別人，節儉自己慷慨別人。';
      if (value === 2) return '很能調度金錢，善理財。逢衝，開銷大，難聚財。';
      if (value === 3)
        return '借錢不用還，借錢給別人不敢要回來，很會賺錢，財容易分散，出手大方，四處投資，錢借給別人不太敢正式的要回來。';
      if (value >= 4)
        return '身強，走運時賺進天下財（皇帝命格），身弱，不走運時散盡天下財（乞丐命格）。不是皇帝就是乞丐財，賺盡天下財，散盡天下財。';
      break;
    }
  }
};

export const getHorseConflict = (summary: Summary, key) => {
  const wordValues = summary.words.map((i) => i.value);
  let result = [];
  switch (key) {
    case '馬': {
      if (wordValues.includes('寅') && wordValues.includes('申'))
        result.push({
          type: '寅、申衝',
          content: '閒不住，開車很快，較會走大路。有車關，易生車禍，手腳較會有問題，要用開運小羅盤。多情，愛管閒事。',
        });
      if (wordValues.includes('巳') && wordValues.includes('亥'))
        result.push({
          type: '巳、亥衝',
          content:
            '辯才無礙，很會辯，口才很好，追根究底，較會鑽小巷。注意車關，易生車禍，必須使用「開運小羅盤」以避車煞。',
        });
      break;
    }
    case '花': {
      if (wordValues.includes('子') && wordValues.includes('午'))
        result.push({
          type: '子、午衝',
          content: '情緒不穩定，人緣很好，很有異性緣，脾氣不好。個性極端，容易腦神經衰弱。',
        });
      if (wordValues.includes('卯') && wordValues.includes('酉'))
        result.push({
          type: '卯、酉衝',
          content:
            '很敏銳，頗具第六感，眼睛銳利，人緣好，異性緣佳。較龜毛，有潔癖，做事有計畫。易受「陰」干擾。不近陰喪事物，不進陰廟。碰上古物及較陰之物，背脊總感覺陰涼。',
        });
      break;
    }
    case '庫': {
      if (wordValues.includes('辰') && wordValues.includes('戌'))
        result.push({
          type: '辰、戌衝',
          content: '自圓其說，自找台階，喜做老大，脾氣不好，理由多。財庫衝開，開銷大。好鬥，好訴訟。',
        });
      if (wordValues.includes('丑') && wordValues.includes('未'))
        result.push({
          type: '丑、未衝',
          content: '打破砂鍋問到底，好問，鑽牛角尖，過度自信，易口角，是非多，易賠錢。財庫衝開，開銷大。凡事多阻逆。',
        });
      break;
    }
  }

  return result;
};

export const getHorseCombination = (key, matches) => {
  const data = Array.from(new Set(matches));
  let result = [];
  switch (key) {
    case '馬': {
      break;
    }
    case '花': {
      if (data.length === 1 && data.includes('年'))
        result.push({ type: '時日月年「ＯＯＯ花」', content: '有長上緣或母親有人緣。' });
      if (data.length === 1 && data.includes('月'))
        result.push({ type: '時日月年「ＯＯ花Ｏ」', content: '自己漂亮或有人緣。' });
      if (data.length === 1 && data.includes('日'))
        result.push({ type: '時日月年「Ｏ花ＯＯ」', content: '配偶漂亮或有人緣。驕傲，無禮（尤其子卯）。' });
      if (data.length === 1 && data.includes('時'))
        result.push({ type: '時日月年「花ＯＯＯ」', content: '對事業有好的緣，子女有人緣。' });
      if (data.length === 2 && data.includes('月') && data.includes('日'))
        result.push({ type: '時日月年「Ｏ花花Ｏ」', content: '性慾高。' });
      if (data.length === 4)
        result.push({ type: '時日月年「花花花花」', content: '如果變格，反而不得人緣，耽誤婚姻。' });
      break;
    }
    case '庫': {
      if (data.length === 1 && data.includes('年'))
        result.push({ type: '時日月年「ＯＯＯ庫」', content: '身強較能得到父母的不動產，賺錢較會放在父母處。' });
      if (data.length === 1 && data.includes('月'))
        result.push({ type: '時日月年「ＯＯ庫Ｏ」', content: '節儉自己，慷慨別人。天干如為財，則表財入庫。' });
      if (data.length === 1 && data.includes('日'))
        result.push({ type: '時日月年「Ｏ庫ＯＯ」', content: '配偶帶庫，聚財有力。亦代表結婚後才較會存錢。' });
      if (data.length === 1 && data.includes('時'))
        result.push({ type: '時日月年「庫ＯＯＯ」', content: '會留不動產給子女，較疼子女。亦代表生孩子後較會存錢。' });
      break;
    }
  }

  return result;
};

export const getGodsStat = (summary: Summary): Record<string, GodStat[]> => {
  const result: Record<string, GodStat> = {};
  summary.words.forEach((word) => {
    // 合化checking
    const selectedCombination = getCombinationChangeByWord(word, summary, null);

    if (selectedCombination?.change?.override) {
      const selectedGod = selectedCombination.change.god;
      const item: GodStat = {
        name: selectedCombination.name,
        word: word.value,
        position: word.position,
        group: word.group,
        value: selectedGod.value,
      };

      if (result[selectedGod.type]) {
        result[selectedGod.type].push(item);
      } else {
        result[selectedGod.type] = [item];
      }
    } else if (selectedCombination?.change?.override === false) {
      const scale = selectedCombination.change.scale.god;
      word.gods.forEach((god) => {
        const item: GodStat = {
          name: selectedCombination.name,
          word: word.value,
          position: word.position,
          group: word.group,
          value: +(god.value * scale).toFixed(2),
        };

        if (result[god.type]) {
          result[god.type].push(item);
        } else {
          result[god.type] = [item];
        }
      });
    } else {
      word.gods.forEach((god) => {
        const item: GodStat = {
          word: word.value,
          position: word.position,
          group: word.group,
          value: god.value,
        };

        if (result[god.type]) {
          result[god.type].push(item);
        } else {
          result[god.type] = [item];
        }
      });
    }
  });

  return result;
};

export const getGodDescription = (summary, god, values) => {
  let result = [];
  switch (god) {
    case '比肩': {
      if (summary.personType === '身強') {
        result.push(
          '身強時\n\n本性不太相信别人，较独立孤独，生活中不管人、事、物常会与人相互争取或计较，同时一定会出现竞争者，感情易出现困扰【比较会失恋】，好胜不服输，善交际，又好客。个性独立，不喜交友，因有过份悲伤的经验。'
        );
      }
      if (summary.personType === '身弱') {
        result.push(
          '身弱時\n\n为人喜欢我行我素，不喜欢束缚，决定一件事后，任何人都无法改变他，在理财行为上，也尽量不要跟会 ( 民间会 )，尽量不要合伙，易被掠夺。'
        );
      }
      if (values.find((i) => i.position === '年')) {
        result.push(
          '比肩在年柱:\n\n小时侯跟父母亲花很多钱。或一辈子钱财被父母或长上劫走。稳健平和，小时候好玩，不喜欢读书，好交朋友，有钱与人平分共用，小时候家庭经济陷入危机，为钱烦恼，父母缺钱或自己身上花钱【看病】，家境不好，一出生家境就不富裕，从小就优柔寡断，乖巧懂事，身兼数职( 分担起家计)。'
        );
      }
      if (values.find((i) => i.position === '月')) {
        result.push(
          '比肩在月柱:\n\n兄弟姊妹排第一，重视自己朋友，先生或太太排最后，因此常遭先生、太太埋怨，亲兄弟姊妹不会帮忙，须靠自己打拼，有钱兄弟朋友多，没钱时无人过问，常为了钱财与人反目，但有钱会借人家。16-33 岁家中会比较困顿或结婚后会经济较差，看到喜欢的对象说不出话来，不善表达，感情易被劫夺，喜欢的人不会是现在的夫或妻。'
        );
      }
      if (values.find((i) => i.position === '日')) {
        result.push(
          '比肩在日柱:\n\n藏干比肩，配偶重视朋友、兄弟、姊妹远超过于你。\n配偶性格刚毅敏捷，思想与己相似。\n追求比己年长，成熟之异性为伴侣，较依赖配偶。'
        );
      }
      if (values.find((i) => i.position === '時')) {
        result.push(
          '比肩在時柱:\n\n年老时生病吃药效果不好，与子女互不喜欢同住在一起。累积一生的钱财到了晚年容易流失，疼儿女，事业上和同事间竞争大，不想依靠子女，做事容易改变决定，故事业难成就。儿女过份疼惜舍不得让他们学习或做事，故较无求生本能且没自信心。'
        );
      }

      break;
    }
  }

  return result.length ? result.join('\n\n') : '準備中';
};

export const getBigYearBySmallYear = (year: number, bigYears: YearWords[]): YearWords => {
  for (let i = bigYears.length; i > 0; i--) {
    const bigYear = bigYears[i - 1];
    if (year >= bigYear.year) {
      return bigYear;
    }
  }
  return null;
};
