import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: rgba(211, 193, 167, 0.3); //ddc1a7
`;

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: ${(p) => p.theme.text};
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`;

export function NotFoundPage() {
  return (
    <>
      <Wrapper>
        <Title>404</Title>
        <p>Page not found.</p>
      </Wrapper>
    </>
  );
}
