import { styled } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { createInvoice, updateData } from 'app/services/api';
import Lottie from 'react-lottie-player';
import lottieJson from '../../../asset/celebrate.json';
import { format } from 'date-fns';
import Dialog from '../Dialog';

const Container = styled.div`
  /* margin-bottom: 16px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FieldLabel = styled.div`
  /* flex: 0 0 90px; */
  font-weight: bold;
`;

const FieldBody = styled.div<{ maxWidth?: string }>`
  flex: 1 1 ${({ maxWidth }): string => maxWidth || 'auto'};
  max-width: ${({ maxWidth }): string => maxWidth || '780px'};
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-line;
  margin-bottom: 16px;
`;

const CreateButton = styled.button`
  border-radius: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
  color: #000;
  border: none !important;
  font-size: 16px;
  padding: 8px 16px;
  outline: none;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #000;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  flex: 1;
  margin: 0;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

const Options = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
`;

const Textarea = styled(TextareaAutosize)`
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
`;

const OptionButton = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: ${(p) => (p.active ? '#5b4f47' : '#fff')};
  color: ${(p) => (p.active ? '#fff' : '#000')};
  /* flex: 1; */
  font-size: 16px;
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  flex: 1;
  background-color: #5b4f47;
  color: #fff;
  border: 1px solid #e0e0e0;
`;

const CancelButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  flex: 1;
  background-color: #fff;
  color: #5b4f47;
  border: 1px solid #e0e0e0;
`;

const Celebrate = styled(Lottie)`
  position: fixed;
  bottom: 24px;
  z-index: 1000;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 8px;
  border: none;
  background-color: #fff;
  color: #000;
`;

const ListItemMain = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

export default function InvoiceList(props: any) {
  const { data, refetch, setLoading } = props;
  const { user, invoice } = data;
  const [createMode, setCreateMode] = useState(false);
  const [formData, setFormData] = useState<any>({ Category: '問題', Method: 'Payme' });
  const [animationDone, setAnimationDone] = useState(false);
  const [updateUser, setUpdateUser] = useState(true);

  const onChangeFormData = (key: string, value: any) => {
    setFormData((i: any) => ({ ...i, [key]: value }));
  };
  const columns = [
    { key: 'Category', label: '類型', value: '問題', type: 'select', options: ['問題', '範疇', '詳批', '其他'] },
    { key: 'Method', label: '支付方法', type: 'select', options: ['Payme', 'FPS', 'Alipay', 'Credit Card', '其他'] },
    { key: 'PIC', label: '負責', type: 'select', options: ['Elise', 'Shirley'] },
    { key: 'Price', label: '價錢', type: 'number' },
    { key: 'Remarks', label: '備註', type: 'textarea' },
  ];

  const renderInput = (i: { key: string; type: string; options?: string[] }) => {
    if (i.type === 'date') {
      return (
        <Input
          placeholder="yyyymmddhhmm"
          type="number"
          pattern="[0-9]*"
          value={formData[i.key]}
          onChange={(e) => onChangeFormData(i.key, e.target.value)}
        />
      );
    }
    if (i.type === 'number') {
      return (
        <Input
          type="number"
          pattern="\d*"
          value={formData[i.key]}
          onChange={(e) => onChangeFormData(i.key, e.target.value)}
        />
      );
    }
    if (i.type === 'text') {
      return <Input value={formData[i.key]} onChange={(e) => onChangeFormData(i.key, e.target.value)} />;
    }
    if (i.type === 'textarea') {
      return <Textarea minRows={2} value={formData[i.key]} onChange={(e) => onChangeFormData(i.key, e.target.value)} />;
    }
    if (i.type === 'select') {
      return (
        <Options>
          {(i.options || []).map((value: string) => (
            <OptionButton
              type="button"
              key={value}
              active={value === formData[i.key]}
              onClick={() => onChangeFormData(i.key, value)}
            >
              {value}
            </OptionButton>
          ))}
        </Options>
      );
    }
    return;
  };

  const onSubmit = async () => {
    if (user['UserId'] && formData.Price > 0) {
      setCreateMode(false);
      setLoading(true);
      await createInvoice(user['UserId'], formData);
      if (updateUser) {
        await updateData(user['UserId'], { Status: '待答覆問題' });
      }
      refetch();
    }
  };

  return (
    <Container>
      {!createMode && (
        <CreateButton
          onClick={() => {
            setCreateMode(!createMode);
            setAnimationDone(false);
          }}
        >
          開單
          <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faPlus} />
        </CreateButton>
      )}
      <div>
        總數:{' '}
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
          ${invoice.reduce((acc: number, i: any) => acc + Number(i['Price']), 0)}
        </span>
      </div>
      <List>
        {invoice?.map((i: any) => (
          <ListItem key={i['Id']}>
            <ListItemMain>
              <div>
                <p>
                  ${i['Price']} {i['Category']}
                </p>
              </div>
              <div>{format(new Date(i['Create date']), 'yyyy-MM-dd HH:mm')}</div>
            </ListItemMain>
            {i['Remarks'] && <p>{i['Remarks']}</p>}
          </ListItem>
        ))}
      </List>
      {createMode && (
        <Dialog onClose={() => setCreateMode(false)} title="開新單" description="請填妥以下資料" hideButtons>
          {columns.map((i, index) => (
            <FieldContainer key={index}>
              <FieldLabel>{i.label}:</FieldLabel>
              <FieldBody>{renderInput(i)}</FieldBody>
            </FieldContainer>
          ))}
          <label>
            <Input type="checkbox" checked={updateUser} onChange={() => setUpdateUser(!updateUser)} />
            <span style={{ marginLeft: '8px' }}>狀態設為"待答覆問題"</span>
          </label>
          <div style={{ display: 'flex', gap: '16px' }}>
            <SubmitButton onClick={onSubmit}>確定</SubmitButton>
            <CancelButton
              onClick={() => {
                setCreateMode(false);
              }}
            >
              取消
            </CancelButton>
          </div>
          {!animationDone && (
            <>
              <Celebrate
                animationData={lottieJson}
                play
                loop={2}
                style={{ width: 250, height: 250, left: '8px' }}
                onComplete={() => setAnimationDone(true)}
              />
              <Celebrate
                animationData={lottieJson}
                play
                loop={2}
                style={{ width: 250, height: 250, right: '8px', transform: 'rotateY(180deg)' }}
              />
            </>
          )}
        </Dialog>
      )}
    </Container>
  );
}
