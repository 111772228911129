import { styled } from 'styled-components';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHistory } from 'app/util/history';
import { UserList } from '../UserList';

const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`;

const Sidebar = styled.div`
  width: 320px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 72px 8px 16px;
  overflow: auto;
`;

const RoundedButton = styled.button`
  border: none !important;
  height: 40px;
  width: 40px;
  font-size: 16px;
  outline: none;
  border-radius: 50%;
  align-items: center;
  background-color: #ededed;
  text-align: center;
  color: #5b4f47;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export default function HistorySidebar(props: any) {
  const { onClose } = props;
  const history = getHistory();

  return (
    <Container>
      <Sidebar>
        <RoundedButton onClick={onClose}>
          <FontAwesomeIcon style={{ fontSize: '16px' }} icon={faClose} />
        </RoundedButton>
        <Title>歷史記錄</Title>
        <UserList users={history} />
      </Sidebar>
    </Container>
  );
}
