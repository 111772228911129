import { styled } from 'styled-components';

const Container = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #faf9f7;
  padding: 16px;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export default function Card(props: any) {
  const { children, title, sectionId, trailing } = props;
  return (
    <Container id={`section-${sectionId}`}>
      <Head>
        <Title>{title}</Title>
        {trailing}
      </Head>
      {children}
    </Container>
  );
}
