// @ts-nocheck
import { Helmet } from 'react-helmet';

export default function DNA(props: any) {
  const { data } = props;
  const scriptText = `
    function getClassByPersonType(persontype) {
    if (persontype == 1 || persontype == 3 || persontype == 5 || persontype == 7)
    return 'likemyside';
    else
    return 'likeotherside';
    }
    function listenFortuneColumnClick() {
    [...document.getElementsByClassName("fortunemergeword")].forEach(i => {
        i.title = i.title.replaceAll("</b>","").replaceAll("<b>","").replaceAll("<br />"," ");
    });
    const fortuneColumns = document.getElementsByClassName("fortunecolumn bfcolumn");
    for (let i = 0; i < fortuneColumns.length; i++) {
      fortuneColumns[i].addEventListener("click", function() {
        const refValue = this.getAttribute("ref");
        const elementToToggle = document.getElementById(refValue);
        const exists = document.getElementsByClassName("currentfortunerow");
        if(exists[0]) {
            exists[0].classList.remove('currentfortunerow');
        }
        if (elementToToggle) {
            elementToToggle.parentElement.classList.toggle("currentfortunerow");
        }
      });
    }
  }
  listenFortuneColumnClick();
  document.getElementsByClassName("brollingfortunes")[0].scrollTo(400,0);
  document.querySelector('.persontype').addEventListener('change', function () {
    var persontypeval = this.value;
    var persontypetext = this.options[this.selectedIndex].text;
    document.querySelectorAll('.daystem').forEach(function (element) {
      element.innerHTML = persontypetext;
    });
    document.querySelectorAll('.bazicontrolbottom').forEach(function (element) {
      element.classList.remove('likemyside');
      element.classList.remove('likeotherside');
      element.classList.add(getClassByPersonType(persontypeval));
    });
  });  
`;

  if (!data) return null;

  return (
    <>
      <div style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: data }} />
      <Helmet>
        <script>{scriptText}</script>
      </Helmet>
    </>
  );
}