import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getData, getReport } from '../../services/api';
import { useSearchParams } from 'react-router-dom';
import DNA from '../../components/DNA';
import ElementStat from '../../components/ElementStat';
import Card from '../../components/Card';
import HorseStat from '../../components/HorseStat';
import GodsStat from '../../components/GodsStat';
import Header from 'app/components/Header';
import LogoImage from 'asset/logo.png';
import { getSummary } from 'app/util';
import YearsElementStat from 'app/components/YearsElementStat';
import Description from 'app/components/Description';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { Field, Form, Input, Label, OptionButton, Options, SubmitButton } from 'app/components/FormElement';
import moment from 'moment';

const Page = styled.div``;

const Logo = styled.img`
  height: auto;
  width: 50vw;
  max-width: 150px;
  border-radius: 32px;
`;

const Loading = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
  background-color: #f6f4ee;
  @media (max-width: 768px) {
    padding: 8px 4px;
  }
`;

export function ReportPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [data, setData] = useState<any>();
  const [selectedYear, setSelectYear] = useState<number>();
  const summary = useMemo(() => (data ? getSummary(data.dna, data.user) : null), [data]);
  const yearOptions = useMemo(
    () => (summary ? summary.years.big.map((i: any) => ({ label: `${i.year}`, value: i.year })) : []),
    [summary]
  );

  const onChangeData = (key: string, value: any) => {
    setFormData((i: any) => ({ ...i, [key]: value }));
  };

  const genderOptions = ['男', '女'];

  const onChangeYear = (data: any) => {
    setSelectYear(data.value);
  };

  useEffect(() => {
    if (yearOptions.length > 0) {
      setSelectYear(yearOptions[0].value);
    }
  }, [yearOptions, setSelectYear]);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (moment(formData.birth, 'YYYYMMDDHmm', true).isValid() && formData.gender) {
      const eight = moment(formData.birth, 'YYYYMMDDHmm').format('MM/DD/YYYY H:mm:ss');
      setLoading(true);
      const res = await getReport({ birth: eight, gender: formData.gender });
      setData(res);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Page>
        <Wrapper>
          <Card>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Card>
        </Wrapper>
      </Page>
    );
  }

  if (data) {
    return (
      <Page>
        <Wrapper>
          <Card sectionId="desc" title="AI">
            <Description summary={summary} descriptionArr={data.description} />
          </Card>
          <Card sectionId="bigYear" title="大運選擇">
            <Select
              options={yearOptions}
              defaultValue={yearOptions[0]}
              onChange={onChangeYear}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F8F0E5',
                  primary: '#5b4f47',
                },
              })}
            />
          </Card>
          <Card sectionId="dna" title="DNA">
            <DNA data={data.dna} />
          </Card>
          <Card sectionId="elements" title="五行性格">
            <ElementStat selectedYear={selectedYear} summary={summary} />
          </Card>
          <Card sectionId="years-elements" title={`流年五行分析 - ${selectedYear}`}>
            {selectedYear && <YearsElementStat selectedYear={selectedYear} summary={summary} />}
          </Card>
          <Card sectionId="horse" title="馬花庫分析">
            <HorseStat summary={summary} />
          </Card>
          <Card sectionId="ten" title="十神分析">
            <GodsStat summary={summary} descriptionArr={data.description} />
          </Card>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      <Wrapper>
        <Card title="創建">
          <Form onSubmit={onSubmit}>
            <Field>
              <Label>八字:</Label>
              <Input
                placeholder="yyyymmddhhmm"
                type="number"
                pattern="[0-9]*"
                value={formData?.birth}
                onChange={(e) => onChangeData('birth', e.target.value)}
              />
            </Field>
            <Field>
              <Label>性別:</Label>
              <Options>
                {genderOptions.map((i) => (
                  <OptionButton
                    type="button"
                    key={i}
                    active={i === formData?.gender}
                    onClick={() => onChangeData('gender', i)}
                  >
                    {i}
                  </OptionButton>
                ))}
              </Options>
            </Field>
            <SubmitButton type="submit">創建</SubmitButton>
          </Form>
        </Card>
      </Wrapper>
    </Page>
  );
}
