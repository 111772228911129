interface Record {
  UserId: string;
  historyDate?: string;
}

export const addHistory = (data: Record) => {
  const record = {
    ...data,
    historyDate: new Date().toLocaleString(),
  };
  const history: Record[] = JSON.parse(localStorage.getItem('history') || '[]') || [];

  const newHistory = [record, ...history.filter((i) => i.UserId !== record.UserId)];
  const max = 20;
  if (newHistory.length > max) {
    newHistory.splice(max, newHistory.length - max);
  }
  localStorage.setItem('history', JSON.stringify(newHistory));
};

export const getHistory = () => {
  return JSON.parse(localStorage.getItem('history') || '[]') || [];
};
