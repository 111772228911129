import { User } from 'app/type';
import { customFormatDistance, formatDate } from 'app/util/common';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserGroup = styled.button`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  min-height: 48px;
  border-radius: 4px;
  padding: 16px;
  border: none;
  background-color: #fff;
  color: #5b4f47;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  font-size: 12px;
`;

export function UserList(props: any) {
  const { users } = props;

  return (
    <List>
      {users.map((user: User) => (
        <UserGroup
          key={user['UserId']}
          onClick={() => {
            window.location.href = `/dashboard?id=${user['UserId']}`;
          }}
        >
          <UserInfo>
            <p style={{ fontWeight: 600, fontSize: '16px' }}>
              <span style={{ color: user['性別'] === '男' ? '#6d96be' : '#fd8587' }}>{user['性別']}</span>{' '}
              {formatDate(user['八字'])}
            </p>
            <p>{user['背景資料']}</p>
          </UserInfo>
          <UserInfo style={{ alignItems: 'flex-end', opacity: 0.8 }}>
            <p>{user['Status']}</p>
            <div>{customFormatDistance(user['Create date'])}</div>
          </UserInfo>
        </UserGroup>
      ))}
    </List>
  );
}
