import { intervalToDuration, addHours } from 'date-fns';
import type { Duration } from 'date-fns';

export const toPercentage = (value: number) => `${(value * 100).toFixed(1)}%`;

export const formatDate = (date: string, plain = false) => {
  if (new Date(date).toString() === 'Invalid Date') return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const hour = String(d.getHours()).padStart(2, '0');
  const min = String(d.getMinutes()).padStart(2, '0');

  if (plain) {
    return `${year}${month}${day}${hour}${min}`;
  }
  return `${year}年${month}月${day}日 ${hour}時${min}分`;
};

export const createNumberArray = (number: number, base = 0): number[] => {
  const result = [];

  for (let i = 0; i < number; i++) {
    result.push(base + i);
  }

  return result;
};

export function customFormatDistance(date: string | Date | number): string {
  if (new Date(date).toString() === 'Invalid Date') return '';
  const duration: Duration = intervalToDuration({
    start: addHours(new Date(date), 8), // TODO
    end: new Date(),
  });

  if (duration.years && duration.years > 0) return `${duration.years}年前`;
  if (duration.months && duration.months > 0) return `${duration.months}月前`;
  if (duration.days && duration.days > 0) return `${duration.days}日前`;
  if (duration.hours && duration.hours > 0) return `${duration.hours}小時前`;
  if (duration.minutes && duration.minutes > 0) return `${duration.minutes}分鐘前`;
  if (duration.seconds && duration.seconds > 0) return `剛剛`;

  return '';
}
